
import { defineComponent, ref } from 'vue';
import ApiService from '@/core/services/ApiService';
import { useRoute } from 'vue-router';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import MapAssessorInstitute from '@/layout/header/partials/assessor/mapAssessorInstituteDrawer.vue';
import moment from 'moment';
import MapAssessorCourseDrawer from '@/layout/header/partials/assessor/mapAssessorCourseDrawer.vue';
import { apiEndpoint } from '@/mixin/apiMixin';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'assessor view',
  components: {
    MapAssessorInstitute,
    MapAssessorCourseDrawer,
    Datatable,
  },
  data() {
    return {
      assessor: {},
      moment: '' as any,
      empDistricts: [],
      updateLogTrainer: [] as any,
      updateLogEmployee: [] as any,
      updateLogEducation: [] as any,
      updateLogProfession: [] as any,
      updateLogCertificate: [] as any,
      changeSummary: false,
      oldEducation: [] as any,
      oldProfession: [] as any,
      oldCertificate: [] as any,
      api_url: '',
      load: false,
      id: this.$route.params.id,
      tabIndex: ref(0),
      InstituteHeader: [
        {
          name: 'Tranche',
          key: 'tranche',
          class: 'text-align: center',
        },
        {
          name: 'Association',
          key: 'association',
          class: 'text-align: center',
        },
        {
          name: 'Training Institute',
          key: 'trainning_institute',
          class: 'text-align: center',
        },
      ],
      CourseHeader: [
        {
          name: 'Tranche',
          key: 'tranche',
          class: 'text-align: center',
        },
        {
          name: 'Association',
          key: 'association',
          class: 'text-align: center',
        },
        {
          name: 'Course',
          key: 'course_name',
          class: 'text-align: center',
        },

        {
          name: 'Training Institute',
          key: 'trainning_institute',
          class: 'text-align: center',
        },
      ],
      CourseBody: [],
      InstituteBody: [],
      actionApprove: false,
      showChangeSummary: false,
      actionEdit: false,
      actionReject: false,
      trainerMapInfo: [],
      componentPartnerKey: 0,
      componentCourseKey: 0,
      token: '' as any,
    };
  },
  async created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.token = urlParams.get('token');
    this.moment = moment;
    this.api_url = this.VUE_APP_API_URL;
    this.load = true;
    await this.getTrainerDetails();
    await this.MapInstututeDetails();
    await this.getUpdateLog();
    this.emitter.on('mapInstitute-updated', async () => {
      await this.MapInstututeDetails();
      this.componentPartnerKey += 1;
    });
    await this.actioncheck();
    this.emitter.on('courseMap-updated', async () => {
      await this.MapCourseDetails();
      this.componentCourseKey += 1;
    });
    await this.MapCourseDetails();
  },
  methods: {
    async actioncheck() {
      let menu = JSON.parse(localStorage.getItem('menu') || '{}');
      if (menu) {
        for (let i = 0; i < menu.length; i++) {
          //console.log(menu[i].action);
          if (menu[i].heading == 'Assessor') {
            //console.log(menu[i].action);
            let actions = menu[i].action;
            //console.log(actions);
            for (let j = 0; j < actions.length; j++) {
              if (actions[j].action_name === 'Approve') {
                this.actionApprove = true;
              }
              if (actions[j].action_name === 'Edit') {
                this.actionEdit = true;
              }
              if (actions[j].action_name === 'Reject') {
                this.actionReject = true;
              }
            }
          }
        }
      }
    },
    Reject(id) {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: 'Are you sure you want to reject it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Rejected!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update(this.VUE_APP_ASSESSOR_REJECT_API + `${id}`, data)
            .then((response) => {
              this.emitter.emit('assesor-updated', true);
              Swal.fire('Rejected!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Approve() {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: 'Are you sure you want to approve it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Approved!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update(
            this.VUE_APP_ASSESSOR_APPROVE_API + `${this.id}`,
            data
          )
            .then((response) => {
              this.emitter.emit('assesor-updated', true);
              Swal.fire('Approved!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Active(id) {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: 'Are you sure you want to Activate it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Activated!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update(
            'entity/assessor/map-status-change/' + `${id}`,
            data
          )
            .then((response) => {
              this.emitter.emit('courseMap-updated', true);
              this.componentCourseKey += 1;
              Swal.fire('Activated!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    InActive(id) {
      let data = {
        active_status: 0,
      };
      Swal.fire({
        title: 'Are you sure you want to Inactivate it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Inactivated!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update(
            'entity/assessor/map-status-change/' + `${id}`,
            data
          )
            .then((response) => {
              this.emitter.emit('courseMap-updated', true);
              this.componentCourseKey += 1;
              Swal.fire('Inactivated!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },

    Suspend(id) {
      let data = {
        active_status: 2,
      };
      Swal.fire({
        title: 'Are you sure you want to suspend it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Suspend!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update(
            'entity/assessor/map-status-change/' + `${id}`,
            data
          )
            .then((response) => {
              this.emitter.emit('courseMap-updated', true);
              this.componentCourseKey += 1;
              Swal.fire('Suspended!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    async getUpdateLog() {
      ApiService.getMobile('entity/assessor/update_log/' + this.id, this.token)
        .then((response) => {
          console.log(response);
          if (response.data.data) {
            this.changeSummary = true;
          }
          this.updateLogTrainer = response.data.data?.assessor_details;
          console.log(this.updateLogTrainer);
          this.updateLogEmployee = response.data.data?.employee_info;
          this.updateLogEducation = response.data.data?.educational_details;
          for (let i = 0; i < this.updateLogEducation.length; i++) {
            //let arr=JSON.parse(this.updateLogEducation[i]);
            let count = Object.keys(this.updateLogEducation[i]).length;
            this.updateLogEducation[i]['count'] = count;
          }

          //console.log(this.updateLogEducation);
          this.updateLogProfession = response.data.data?.professional_details;
          for (let i = 0; i < this.updateLogProfession.length; i++) {
            //let arr=JSON.parse(this.updateLogEducation[i]);
            let count = Object.keys(this.updateLogProfession[i]).length;
            this.updateLogProfession[i]['count'] = count;
          }
          this.updateLogCertificate = response.data.data?.certificate;
          for (let i = 0; i < this.updateLogCertificate.length; i++) {
            //let arr=JSON.parse(this.updateLogEducation[i]);
            let count = Object.keys(this.updateLogCertificate[i]).length;
            this.updateLogCertificate[i]['count'] = count;
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    addCourseMap() {
      //console.log(this.id);
      this.emitter.emit('entity_add_map_assessor_course_data', this.id);
    },
    mapassessorinstitute() {
      this.emitter.emit(
        'entity-add-map-assessor-Institute-data',
        this.assessorid
      );
    },
    async MapInstututeDetails() {
      let mapping_type = '1';

      await ApiService.getMobile(
        'entity/assessor/map_assessor_info?entity_assessor_id=' +
          this.assessorid +
          '&mapping_type=' +
          mapping_type +
          '&all=1',
        this.token
      )
        .then((response) => {
          this.InstituteBody = response.data.data;
          console.log(this.InstituteBody);
          this.componentPartnerKey += 1;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    setActiveTab(event) {
      this.tabIndex = parseInt(event.target.getAttribute('data-tab-index'));
    },
    async MapCourseDetails() {
      let mapping_type = '2';

      await ApiService.getMobile(
        'entity/assessor/map_assessor_info?entity_assessor_id=' +
          this.assessorid +
          '&mapping_type=' +
          mapping_type +
          '&all=1',
        this.token
      )
        .then((response) => {
          this.CourseBody = response.data.data;
          console.log(this.CourseBody);
          this.componentPartnerKey += 1;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTrainerDetails() {
      this.load = true;
      await ApiService.getMobile(
        'entity/assessor/show?assessor_id=' + `${this.id}`,
        this.token
      )
        .then((response) => {
          console.log(response);
          this.load = false;
          this.assessor = response.data.data;
          console.log(this.trainer);
          this.componentCourseKey += 1;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const route = useRoute();
    const assessorid = route.params.id;
    return { assessorid };
  },
});
